import "babel-polyfill";//es6转es5兼容IE
import "url-search-params-polyfill";//让ie兼容new URLSearchParams()
import Vue from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios';
import store from './store';
import qs from 'qs';
import CryptoJS from 'crypto-js/crypto-js';
import  md5 from 'js-md5';
import VueCookies from 'vue-cookies';
import config from './assets/js/conf/config';
import request from './assets/js/utils/request';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipboard from 'vue-clipboard2';
import '../theme/index.css';
import Meta from 'vue-meta';
import CustomerService from '@/plugins/customerService'


Vue.config.productionTip = false;

Vue.prototype.$config=config;
Vue.prototype.$qs = qs
Vue.prototype.$axios = axios
Vue.prototype.$md5 = md5
Vue.prototype.$request = request
Vue.use(VueCookies)
Vue.use(ElementUI)
Vue.use(VueClipboard)
Vue.use(Meta)
Vue.use(CustomerService)

new Vue({
	metaInfo(){
		return {
			title:'星好货',
			meta: [
				{name:'referrer',content:'never'}
			]
		}
	},
  store,
  router,
  render: h => h(App),
}).$mount('#app');
