import Vue from 'vue';
import Router from 'vue-router';
import config from './assets/js/conf/config';
import request from './assets/js/utils/request';


Vue.use(Router);
let ucmenu = []
function getUCmenu(){
	request.post(config.baseApi + "/business/base/getMenu").then((res)=>{
		if(res.status = 200){
			localStorage["ucmenu"]=JSON.stringify(res.data)
		}
	})
}

let router = new Router({
	mode: "history", //1、hash哈希：有#号。2、history历史：没有#号
	base: process.env.BASE_URL, //自动获取根目录路径
	scrollBehavior: (to, from, position) => {
		if (position) {
			return position
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	},
	routes: [{
			path: "/",
			name: "index",
			component: () => import("./pages/index"),
		},
		{
			path: "/detail",
			name: "detail",
			component: () => import("./pages/detail")
		},
		{
			path: "/ucenter",
			name: "ucenter",
			component: () => import("./pages/ucenter"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_goods",
			name: "ucenter goods",
			component: () => import("./pages/uc_goods"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_goods_connect",
			name: "ucenter goods connect",
			component: () => import("./pages/uc_goods/connect"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_order",
			name: "ucenter order",
			component: () => import("./pages/uc_order"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_order_relate",
			name: "ucenter order relate",
			component: () => import("./pages/uc_order/relate"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_order_detail",
			name: "ucenter order detail",
			component: () => import("./pages/uc_order/detail"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_order_aftersale",
			name: "ucenter order aftersale",
			component: () => import("./pages/uc_order/aftersale"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_order_detail_saled",
			name: "ucenter order detail saled",
			component: () => import("./pages/uc_order/saled"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_wallet",
			name: "ucenter wallet",
			component: () => import("./pages/uc_wallet"),
			meta:{auth:true,authority:true}
		},
		
		{
			path: "/uc_shangjia",
			name: "ucenter shangjia",
			component: () => import("./pages/uc_shangjia"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_setting",
			name: "ucenter setting",
			component: () => import("./pages/uc_setting"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_extend",
			name: "ucenter extend",
			component: () => import("./pages/uc_extend"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_position",
			name: "ucenter position manage",
			component: () => import("./pages/uc_position"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_employee",
			name: "ucenter employee manage",
			component: () => import("./pages/uc_employee"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_pwd",
			name: "ucenter set Password",
			component: () => import("./pages/uc_pwd"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/notice",
			name: "notice center",
			component: () => import("./pages/notice")
		},
		{
			path: "/settle",
			name: "settlement",
			component: () => import("./pages/settle"),
			meta:{auth:true}
		},
		{
			path: "/cart",
			name: "cart",
			component: () => import("./pages/cart")
		},
		{
			path: "/vip",
			name: "vip buy",
			component: () => import("./pages/vip"),
			meta:{auth:true}
		},
		{
				path: "/search_index",
				name: "search_index",
				component: () => import("./pages/search/index")
			},
		{
				path: "/search_image",
				name: "search_image",
				component: () => import("./pages/search/image")
			},
		{
			path: "/supplier",
			name: "supplier center",
			component: () => import("./pages/supplier")
		},
		{
			path: "/uc_goods2",
			name: "ucenter goods2",
			component: () => import("./pages/uc_goods2"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_order2",
			name: "asdads",
			component: () => import("./pages/uc_order2"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/uc_after",
			name: "ucenter after",
			component: () => import("./pages/uc_after"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/collect",
			name: "collect",
			component: () => import("./pages/collect"),
			meta:{auth:true,authority:true}
		},
		{
			path: "/search_shop",
			name: "search_shop",
			component: () => import("./pages/search/shop")
		}
	]
});
router.beforeEach((to, from, next) => {
	ucmenu = localStorage['ucmenu'] ? JSON.parse(localStorage['ucmenu']) :[]
	if(localStorage['auth'] && ucmenu && ucmenu.length == 0){
		getUCmenu()
		return false
	}
	if(!to.name){//路径错误跳转，可替换404页面
		next("/");
	}
	if (to.meta.auth) {
		if (localStorage['auth']) {//需要登录验证且已登录
			if(to.meta.authority){
				let item = ucmenu.find(item => {
					return item.url == "."+to.path;
				});
				let isChild = strLink("."+to.path)
				// console.log('_______________',item,isChild)
				if(item || isChild){//有进入该页面的权限
					next()
				}else{//没有该页面的权限，但通过其他方式进入
					alert('权限不足！')
					next("/")
				}
			}else{
				next();
			}
		} else {//需要登录验证但未登录
			next("/");
		}
	} else {//不需要登录验证即可跳转页面
		next();
	}
});
function strLink(str){
	ucmenu = localStorage['ucmenu'] ? JSON.parse(localStorage['ucmenu']) :[]
	let c = ""
	for(let i in ucmenu){
		if(str.indexOf(ucmenu[i].url) != -1){
			c = str.replace(ucmenu[i].url,"")
			break;
		}
	}
	return Boolean(c)
}
export default router;
