<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {

    }
  },
  mounted() {
    /*
    if(localStorage.getItem('userinfo')){
      ssq.push('clearUser');
      let user = JSON.parse(localStorage.getItem('userinfo'));
      ssq.push('setLoginInfo', {
        user_id:'xhh'+ user.mobile, // 加密后的用户id, 必填！
        user_name: user.mobile, // 对应用户名
        phone: user.mobile, // 对应用户手机号
      });
      window.__ssc.setting = { hideIcon: false }; //true为隐藏，false为不隐藏
    }else{
      ssq.push('clearUser');
      window.__ssc.setting = { hideIcon: true }; //true为隐藏，false为不隐藏
    }
    */
  }
}
</script>

<style>
    
</style>
