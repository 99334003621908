import Vue from 'vue'
import FloatingQrCode from '@/components/CustomerService/FloatingQrCode.vue'

export default {
  install() {
    // 创建全局悬浮组件
    const ServiceComponent = Vue.extend(FloatingQrCode)
    const instance = new ServiceComponent()
    instance.$mount()
    document.body.appendChild(instance.$el)
  }
} 