<template>
  <div>
    <div class="floating-service" v-if="visible">
      <div class="service-card">
        <div class="close-btn" @click="closeService">×</div>
        <div class="qrcode-container" @click="showLargeQR">
          <img :src="qrCodeUrl" alt="客服二维码" class="qrcode-img">
        </div>
        <div class="service-text">
          <p class="title">扫码添加客服</p>
          <p class="desc">随时为您提供帮助</p>
        </div>
      </div>
    </div>
    
    <!-- 放大显示遮罩层 -->
    <div class="qr-overlay" v-if="showLarge" @click="hideLargeQR">
      <div class="large-qr-container" @click.stop>
        <div class="large-close-btn" @click="hideLargeQR">×</div>
        <img :src="qrCodeUrl" alt="客服二维码" class="large-qr-img">
       
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FloatingQrCode",
  data() {
    return {
      visible: true,
      showLarge: false,
      qrCodeUrl: require('@/assets/images/customer-qr.png') // 确保这个路径指向您项目中实际存在的图片
    };
  },
  methods: {
    closeService() {
      this.visible = false;
    },
    showLargeQR() {
      this.showLarge = true;
    },
    hideLargeQR() {
      this.showLarge = false;
    }
  }
};
</script>

<style scoped>
.floating-service {
  position: fixed;
  right: 20px;
  bottom: 120px; /* 位置上移 */
  z-index: 9999; /* 确保足够高的z-index */
}

.service-card {
  width: 120px; /* 减小宽度 */
  background-color: #2c3136;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  padding: 10px; /* 减小内边距 */
  position: relative;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-btn {
  position: absolute;
  font-size: 24px;
  top: -19px;
  right: -5px;
  color: #aaa;
  font-size: 20px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  z-index: 1; /* 确保关闭按钮在最上层 */
}

.close-btn:hover {
  color: #fff;
}

.qrcode-container {
  width: 100px; /* 减小二维码尺寸 */
  height: 100px;
  margin: 0 auto;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.qrcode-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.service-text {
  margin-top: 10px;
  width: 100%;
}

.title {
  font-size: 12px; /* 缩小字体 */
  font-weight: bold;
  margin: 5px 0;
}

.desc {
  font-size: 10px; /* 缩小字体 */
  color: #aaa;
  margin: 0;
}

/* 大图模式样式 */
.qr-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.large-qr-container {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 350px;
  width: 90%;
}

.large-close-btn {
  position: absolute;
  top: -16px;
  right: -16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #2c3136;
  color: white;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.large-qr-img {
  width: 100%;
  max-width: 280px;
  height: auto;
}

.large-qr-text {
  font-size: 16px;
  color: #333;
  margin-top: 15px;
  font-weight: bold;
}
</style> 